@use 'sass:map';
@import-normalize;
$theme-colors: (
  'primary': #f68d2e,
  'success': #039855,
  'warning': #eaaa08,
  'blue': #2e90fa,
  'indigo': #6172f3,
  'purple': #7a5af8,
  'pink': #ee46bc,
  'red': #f04438,
  'orange': #fb9a31,
  'yellow': #eaaa08,
  'green': #16b364,
  'teal': #15b79e,
  'cyan': #06aed4,
  'gray-100': #f5f5f5,
  'gray-200': #e5e5e5,
  'gray-300': #d6d6d6,
  'gray-400': #a3a3a3,
  'gray-500': #737373,
  'gray-600': #737373,
  'gray-700': #424242,
  'gray-800': #292929,
  'gray-900': #141414,
  'primary-50': #fef3e2,
  'primary-300': #fdb85e,
  'black': #0d0d0d,
);
$primary: #f68d2e;
$border-radius: 8px;
$alert-margin-bottom: 20px;
$modal-content-border-radius: 12px;
$dropdown-link-hover-bg: '#f5f5f5';
$border-radius-lg: 0.75rem;
$dropdown-padding-x: 0.25em;
$dropdown-padding-y: 0.25em;
$dropdown-link-active-bg: '#FCFCFC';
$dropdown-link-active-color: #141414;
$toast-border-color: #f5f5f5;
$toast-padding-y: 16px;
$toast-padding-x: 16px;
@import '~bootstrap/scss/bootstrap';
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: map.get($theme-colors, 'gray-900');
  text-decoration: none;
}
a:hover {
  color: $primary;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
input {
  font-size: 16px;
  line-height: 1.5;
}
img, svg {
  max-width: 100%;
  vertical-align: top;
}
.ds-selector {
  border: transparent !important;
  background-color: #f68d2e !important;
  opacity: 0.25 !important;
  border-radius: 6px;
}
.hover:hover {
  color: inherit;
}
.dropdown-item {
  border-radius: 6px;
}

#sessionFrame {
  position: absolute;
  width: 1px;
  height: 1px;
  top: -1000px;
}
